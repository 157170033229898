import './App.css';
import StartPage from './components/StartPage';
function App() {
  return (
    <div className="App">
      <StartPage />
    </div>
  );
}

export default App;
