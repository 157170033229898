import { useState, useEffect } from 'react'
import Square from "./square/Square"
import Switch from './switch/Switch'
import refresh from '../assets/refresh.png'
import home from '../assets/home.png'
import './Game.css'

const Game = (props) => {
    const indices = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    const [winner, setWinner] = useState(null);
    const [winnerSquares, setWinnerSquares] = useState([]);
    const [theme, setTheme] = useState({ current: localStorage.getItem('theme') === null ? 'light' : localStorage.getItem('theme') });
    const initData = () => {
        let obj = {};
        indices.forEach(index => {
            obj[`square${index}`] = { data: null, isFilled: false }
        })
        return obj;
    }
    const [gameData, setGameData] = useState(initData());
    const [current, setCurrent] = useState({ piece: 'X', count: 0, gameOver: false });
    const resetGame = () => {
        setWinner(null);
        setWinnerSquares([]);
        setGameData(initData());
        setCurrent({ piece: 'X', count: 0, gameOver: false });
    }

    useEffect(() => {
        if (!current.gameOver) {
            if (!checkGame() && current.count < 9 && !current.gameOver && current.piece === 'O') props.hardMode ? playHardGame() : playGame()
            if (current.count === 9 && winner === null) {
                if (!checkGame()) {
                    setWinner('No Winner')
                    setCurrent({ ...current, gameOver: true })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current])

    const playHardGame = () => {
        if (current.count === 1) playGame()
        else {
            let flag = true;
            const checkIfAny2filled = (data) => {
                let count = 0;
                data.forEach(d => { count = count + (d.isFilled ? 1 : 0) })
                return count >= 2 ? (count === 3 ? false : true) : false;
            }
            const checkIfAny2Same = (data,val) => {
                return ((data[0].data === data[1].data || data[1].data === data[2].data || data[0].data === data[2].data) 
                    && (data[0].data === val || data[1].data === val || data[2].data === val))
            }
            const fillData=(a,b,c)=>{
                if (gameData[`square${a}`].data === gameData[`square${b}`].data) onClick(`square${c}`);
                else if (gameData[`square${b}`].data === gameData[`square${c}`].data) onClick(`square${a}`);
                else onClick(`square${b}`);
            }
            const checkDataHard=(a,b,c,val)=>{
                if (checkIfAny2filled([gameData[`square${a}`], gameData[`square${b}`], gameData[`square${c}`]])) {
                    if (checkIfAny2Same([gameData[`square${a}`], gameData[`square${b}`], gameData[`square${c}`]],val)) {
                        flag = false;
                        fillData(a,b,c)
                        return true
                    }
                } 
                return false
            }
            //rows-o
            for (let i = 0; i < 9; i = i + 3)  if(checkDataHard(i,i+1,i+2,'O')) return
            //cols-o
            for (let i = 0; i < 3; i++) if(checkDataHard(i,i+3,i+6,'O')) return
            //diagonals-o
            if(checkDataHard(0,4,8,'O')) return
            if(checkDataHard(2,4,6,'O')) return

            //rows-x
            for (let i = 0; i < 9; i = i + 3)  if(checkDataHard(i,i+1,i+2,'X')) return
            //cols-x
            for (let i = 0; i < 3; i++) if(checkDataHard(i,i+3,i+6,'X')) return
            //diagonals-x
            if(checkDataHard(0,4,8,'X')) return
            if(checkDataHard(2,4,6,'X')) return
            if (flag) playGame()
        }
    }

    const playGame = () => {
        let randomIndex = Math.floor(Math.random() * 9);
        if (!gameData[`square${randomIndex}`].isFilled) {
            onClick(`square${randomIndex}`);
        }
        else playGame()
    }

    const checkData = (a, b, c) => {
        if (gameData[`square${a}`].isFilled && gameData[`square${b}`].isFilled && gameData[`square${c}`].isFilled) {
            if ((gameData[`square${a}`].data === gameData[`square${b}`].data)
                && (gameData[`square${b}`].data === gameData[`square${c}`].data)) {
                announceWinner(gameData[`square${a}`].data, [`square${a}`, `square${b}`, `square${c}`])
                setCurrent({ ...current, gameOver: true })
                return true;
            }
        }
    }

    const checkRows = () => {
        for (let i = 0; i < 9; i = i + 3) if (checkData(i, i + 1, i + 2)) return true;
        return false;
    }

    const checkColumns = () => {
        for (let i = 0; i < 3; i++) if (checkData(i, i + 3, i + 6)) return true;
        return false;
    }

    const checkDiagnols = () => {
        if (checkData(0, 4, 8)) return true;
        if (checkData(2, 4, 6)) return true;
        return false;
    }

    const checkGame = () => {
        return (checkRows() || checkColumns() || checkDiagnols())
    }

    const announceWinner = (data, arr) => {
        setWinner(data === 'X' ? 'You Won' : 'Code Won');
        setWinnerSquares(arr);
    }

    const onClick = (props) => {
        if (!gameData[`${props}`].isFilled && !current.gameOver) {
            setGameData({ ...gameData, [`${props}`]: { ...gameData[`${props}`], data: current.piece, isFilled: true } })
            setCurrent({ ...current, piece: current.piece === 'X' ? 'O' : 'X', count: current.count + 1 })
        }
    }

    useEffect(() => {
        document.body.style.background = (theme.current === 'light' ? 'white' : 'rgba(0, 0, 0, 0.934)');
        localStorage.setItem('theme', theme.current)
    }, [theme])

    const handleTheme = () => {
        setTheme({ ...theme, current: theme.current === 'dark' ? 'light' : 'dark' })
    }

    return (
        <div className='game-outer-container'>
            <div className='game-settings'><Switch handleTheme={handleTheme} theme={theme} /></div>
            <div className='game-container'>
                <div className='game'>
                    {
                        indices.map(index => {
                            return <Square
                                data={gameData[`square${index}`].data}
                                id={`square${index}`}
                                key={`square${index}`}
                                onClick={onClick}
                                theme={theme}
                                winnerSquares={winnerSquares} />
                        })
                    }
                </div>
            </div>
            {current.gameOver && (
                <div className='game-over-outer-container'>
                    <div className={`game-winner dark ${winner === null ? 'hide' : winner}`}>
                        <span className='game-winner-text'>{winner}</span>
                    </div>
                    <div className='game-over-container'>
                        <div className='game-over-action' onClick={resetGame}>
                            <img src={refresh} alt='' width={60} />
                        </div>
                        <div className='game-over-action' onClick={() => { window.location.reload() }}>
                            <img src={home} alt='' width={47} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Game