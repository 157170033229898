import './Switch.css'
import sun from '../../assets/sun.png'
import moon from '../../assets/moon.png'

const Switch=(props)=>{
    return(
        <div className='switch-container'>
            {props.theme.current==='dark' 
            ? <div className='switch-icon'><img src={moon} alt='moon' width={48} /></div>
            : <div className='switch-icon'><img src={sun} alt='sun' /></div>}
            <div className='switch-container-inner'>
                <label className="switch">
                    <input type="checkbox" onChange={props.handleTheme} checked={props.theme.current==='dark'} />
                    <span className='slider round'></span>
                </label>
            </div>
        </div>
    )
}
export default Switch