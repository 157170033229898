import './StartPage.css'
import {useState} from 'react'
import Game from './Game.js'
import play from '../assets/play.png'
const StartPage=()=>{

    const [btnData,setBtnData]=useState('');
    const [gameStarted,setGameStarted]=useState(false)
    
    const btnClick=(e)=>{
        setBtnData(`${e.target.id}`)
    }

    const startGame=()=>{
        if(btnData!=='') setGameStarted(true)
    }
    
    return gameStarted?(<Game hardMode={btnData==='btn2'} />):(
        <div className="start-page-container">
            <div className="start-page heading-container">TIC-TAC-TOE</div>
            <div className='start-page-mode-container'>
                <div className={`start-page mode ${btnData==='btn1'?'clicked-btn1':''}`} 
                    id='btn1' onClick={(e)=>{btnClick(e)}}>Easy</div>
                <div className={`start-page mode ${btnData==='btn2'?'clicked-btn2':''}`} 
                    id='btn2' onClick={(e)=>{btnClick(e)}}>Hard</div>
            </div>
            <div className='start-btn-container'>
                <div className='start-page start-btn' onClick={()=>startGame()}>
                    <img src={play} alt='' width={60} />
                </div>
            </div>
        </div>
    )
}
export default StartPage